import React from "react";
import {Tabs} from "react-tabs";
import MainPricing from "./MainPricing";

const Pricing = () => {
  return (
    <div>
      <Tabs>
        <div className="pricing-table-area-four">
            <MainPricing />
        </div>
      </Tabs>
    </div>
  );
};

export default Pricing;
