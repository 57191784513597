import React, {useState} from "react";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import docco from "react-syntax-highlighter/dist/esm/styles/hljs/docco";
import TextField from "@mui/material/TextField";
import server from '../../../server';

const codeString = `curl --request POST 'https://api.zizy.io/transaction' \\
        --header 'Content-Type: application/json' \\
        --header 'X-API-Key: API_KEY' \\
        --data-raw '
            {
                "merchant_name": "APPLE.COM/BILL",
                "country": "IE"
            }'`;

let error = 2;

const DocFullWidth = () => {
    const [emailInput, setEmailInput] = React.useState();
    const [emailError, setEmailError] = React.useState(error);

    // useDocumentTitle("Doc Full Width || Deski-Saas & Software React Template");
    const handleSubmit = (event) => {
        event.preventDefault();
    };


    const handleChange = (event) => {
        event.preventDefault();
        setEmailInput(event.target.value);
        if (emailInput === "") {
            setEmailError(2);
        }
    };


    // let emailError = 2;
    const ERROR_MESSAGE = "There is a problem with your email.";
    const OK_MESSAGE = "Great. Please check your email.";
    // let emailInput = "";

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    return (
        <div>
            <div className="doc-container full-width">
                <div className="clearfix">
                    <div className="row flex-xl-nowrap no-gutters">
                        {/* ****************************** DOC SIDEBAR ********************************* */}
                        <div className="col-md-3 col-xl-2 ">

                        </div>
                        {/*  /.doc-sidebar */}

                        {/* ****************************** DOC MAIN BODY ********************************* */}
                        <main className="col-md-9 col-xl-8 doc-main-body">

                            <div id="manual-installation">


                                {/*<h3>Manual Installation </h3>*/}
                                {/*<p>*/}
                                {/*    With only one API endpoint you have endless posibilities.*/}
                                {/*    {'\n'}*/}
                                {/*    Check out the <code><a href="https://api.zizy.io/docs">docs here </a></code>.*/}
                                {/*</p>*/}

                                <SyntaxHighlighter showLineNumbers showInlineLineNumbers language="curl"
                                                   style={docco}>
                                    {codeString}
                                </SyntaxHighlighter>

                                <p>{'\n'}</p>

                                <div style={{textAlign: "center"}}>

                                    {emailError === 2 ? (<br/>) : null}
                                    {emailError === 0 ?
                                        <p style={{color: "red", fontSize: 12}}>{ERROR_MESSAGE}</p> : null}
                                    {emailError === 1 ?
                                        <p style={{color: "green", fontSize: 12}}>{OK_MESSAGE}</p> : null}



                                    {/*<p style={{fontSize: 10, color: "grey"}}>By using the API you agree with our*/}
                                    {/*    Terms of Use*/}
                                    {/*    and Privacy Policy.</p>*/}
                                </div>
                                <p></p>
                                <div className="mark-blue">
                  <pre>
                    With only one API endpoint you have endless posibilities.
                      {'\n'}
                      Check out the full <code><a href="https://docs.zizy.io">API documentation</a></code> here.
                      {'\n'}
                      <span style={{fontSize: 10, color: "grey"}}>*By using the API you agree with our
                          Terms of Use
                          and Privacy Policy.</span>
                  </pre>
                                </div>
                                {/*/.mark-blue  */}
                            </div>

                        </main>

                        {/* End Right Sidebar Menu */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocFullWidth;
