import React from "react";

const PricingContent = [
  {
    packName: "Free",
    packDetails: "Proof of concept",
    features: [
      "Full access to our systems",
      "Non-restricted use of the data",
      "Worldwide coverage",
      "Standardised high-quality merchant logos"
    ],
    animationDelay: "",
    activeClass: "",
  },
  {
    packName: "Enterprise",
    packDetails: "For companies who have a growing number of transactions per month",
    bgColor: "#E2F2FD",
    price: "",
    durationNumber: "Let's chat",
    facility: "for a custom offer",
    features: [
        "Everything in free + ",
      "Access to all merchants",
      "Unlimited transactions/month",
        "Categories",
        "Subscriptions"
    ],
    animationDelay: "100",
    activeClass: "active"//"active most-popular",
  },
  // {
  //   packName: "Business",
  //   packDetails: "For individuals and teams",
  //   bgColor: "#FFEBEB",
  //   price: "37",
  //   durationNumber: "All users, monthly",
  //   facility: "for unlimited users",
  //   features: [
  //     "Unlimited Email",
  //     "5gb Hosting",
  //     "2 website 3 sub domain",
  //     "Email & Live chat",
  //     "Backling",
  //     "Discount Programe",
  //   ],
  //   animationDelay: "200",
  //   activeClass: "",
  // },
];

const PricingMonthly = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div
          className="col-lg-4 col-sm-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.animationDelay}
          key={i}
        >
          <div className={`pr-table-wrapper ${val.activeClass}`}>
            <div className="pack-name">{val.packName}</div>
            <div className="pack-details">{val.packDetails}</div>
            <div
              className="top-banner d-md-flex"
              style={{ background: val.bgColor }}
            >
              <div className="price">
                {/*<sup>$</sup>*/}
                {val.price}
              </div>
              <div>
                <span>{val.durationNumber}</span>
                <em>{val.facility}</em>
              </div>
            </div>
            {/* /.top-banner */}
            <ul className="pr-feature">
              {val.features.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
            {/*<a href="#" className="trial-button">*/}
            {/*  Start 30 days free trial*/}
            {/*</a>*/}
            {/*<div className="trial-text">No card required, cancel any time</div>*/}
          </div>
          {/* /.pr-table-wrapper */}
        </div>
      ))}
    </div>
  );
};

export default PricingMonthly;
