import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = () => {
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 68) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-one bg-none  fixed"
            : "theme-main-menu sticky-menu theme-menu-one bg-none"
        }
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo">
            <Link to="/">
              <img src="images/logo/zizy_main_logo.png" alt="brand logo" />
            </Link>
          </div>
          {/* End Logo */}

          {/*<nav id="mega-menu-holder" className="navbar navbar-expand-lg">*/}
          {/*  <div className="container nav-container">*/}
          {/*    <div*/}
          {/*      className="navbar-collapse collapse"*/}
          {/*      id="navbarSupportedContent"*/}
          {/*    >*/}
          {/*      <div className="d-lg-flex align-items-center">*/}
          {/*        <MegaMenu />*/}
          {/*        /!* End MegaMenu *!/*/}

          {/*        <ul className="right-button-group d-flex align-items-center justify-content-center">*/}
          {/*          <li>*/}
          {/*            <Link to="login" className="signIn-action">*/}
          {/*              Login*/}
          {/*            </Link>*/}
          {/*          </li>*/}
          {/*          <li>*/}
          {/*            <Link to="signup" className="signUp-action">*/}
          {/*              Get Started*/}
          {/*            </Link>*/}
          {/*          </li>*/}
          {/*        </ul>*/}
          {/*        /!* End right-button-group  *!/*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</nav>*/}
          {/* End nav */}
        </div>

        {/*<MegaMenuMobile />*/}
        {/* 	End Mega Menu for Mobile */}
      </div>
      {/* /.theme-main-menu */}
    </>
  );
};

export default Header;
