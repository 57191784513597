import React from "react";
import { Link } from "react-router-dom";

const FancyFeatureEleven = () => {
  return (
    <>
      <div className="block-style-sixteen" style={{ background: "#EBF3F1" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#DEEAE7" }}
              >
                <img src="images/icon/73.svg" alt="icon" />
              </div>
              <h3 className="title">Visual enrichments</h3>
              <p>
               Get clean merchant names, logos, categories. For card transactions, bank transfers and stocks.
                 Enhance your feed, provide better products.
              </p>

              {/*<h6>Clean merchant names, logos, categories. Stocks logos.</h6>*/}
              {/*<h6> {'\n'} You name it, we provide it.</h6>*/}
              {/*<Link to="/solution-management">*/}
              {/*  <img src="images/icon/72.svg" alt="icon" />*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
        <img
          src="images/assets/enrichment.png"
          alt=""
          className="shapes screen-one"
        />
        {/*<img src="images/assets/enrichment.png" alt="icon" />*/}
      </div>
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#EDF3F9" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6 ml-auto"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#E1E7ED" }}
              >
                <img src="images/icon/75.svg" alt="icon" />
              </div>
              <h3 className="title">Subscriptions</h3>
              <p>
                Detect recurring transactions. Emphasize, block, analyze.
              </p>
              {/*<Link to="/solution-management">*/}
              {/*  <img src="images/icon/72.svg" alt="icon" />*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
        <img
          src="images/assets/subscriptions.png"
          alt="screen"
          className="shapes screen-two"
          style={{ "padding-left":"12%", "width": "45%"}}
        />
      </div>
      {/* /.block-style-sixteen */}

      <div className="block-style-sixteen" style={{ background: "#F9F1EF" }}>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-5 col-lg-6"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <div
                className="icon d-flex align-items-center justify-content-center"
                style={{ background: "#EDE5E3" }}
              >
                <img src="images/icon/74.svg" alt="icon" />
              </div>
              <h3 className="title">Geolocation</h3>
              <p>
                Get location for transactions. Enhance the UI, prevent fraud, improve statistics.
              </p>
              {/*<Link to="/solution-management">*/}
              {/*  <img src="images/icon/72.svg" alt="icon" />*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
        <img
          src="images/assets/geolocation.png"
          alt="screen"
          className="shapes screen-three"
          style={{ "padding-right":"10%", "width": "40%"}}
        />
      </div>

      <div className="block-style-sixteen" style={{ background: "#EBF3F1" }}>
        <div className="container">
          <div className="row">
            <div
                className="col-xl-5 col-lg-6 ml-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
            >
              <div
                  className="icon d-flex align-items-center justify-content-center"
                  style={{ background: "#E1E7ED" }}
              >
                <img src="images/icon/92.svg" alt="icon" />
              </div>
              <h3 className="title">Credit risk scoring</h3>
              <p>
                Get transactions risk insights.
                Minimize risk & default rate for credit products.
              </p>
              {/*<Link to="/solution-management">*/}
              {/*  <img src="images/icon/72.svg" alt="icon" />*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
        <img
            src="images/assets/creditrisk.png"
            alt="screen"
            className="shapes screen-two"
            style={{}}
        />
      </div>

      <div className="block-style-sixteen" style={{ background: "#F9F1EF" }}>
        <div className="container">
          <div className="row">
            <div
                className="col-xl-5 col-lg-6"
                data-aos="fade-right"
                data-aos-duration="1200"
            >
              <div
                  className="icon d-flex align-items-center justify-content-center"
                  style={{ background: "#EDE5E3" }}
              >
                <img src="images/icon/51.svg" alt="icon" />
              </div>
              <h3 className="title">Fraud detection</h3>
              <p>
                Get transactions fraud insights. Comply with local regulations & protect against bad players.
              </p>
              {/*<Link to="/solution-management">*/}
              {/*  <img src="images/icon/72.svg" alt="icon" />*/}
              {/*</Link>*/}
            </div>
          </div>
        </div>
        <img
            src="images/assets/fraudprevention.png"
            alt="screen"
            className="shapes screen-three"
            style={{ }}
        />
      </div>
      {/*  /.block-style-sixteen */}
    </>
  );
};

export default FancyFeatureEleven;
