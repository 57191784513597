import React from "react";

import TermsConditions from "../views/inner-pages/features/miscellaneous/TermsConditions";

// Not Found Page
import NotFound from "../views/NotFound";

// Route Specific
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";
import ProductLanding from "../views/all-home-pages/ProductLanding";

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={ProductLanding} />
          <Route path="/terms-conditions" component={TermsConditions} />
          {/* NotFound Route */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
