import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const LogoSlider = [
  {
    img: "commerzbank_logo",
    url: "",
  },
  {
    img: "kroo_logo",
    url: "",
  },
  {
    img: "trade_republic_logo",
    url: "",
  },
  {
    img: "thanks_ben_logo",
    url: "",
  },
  {
    img: "findomestic_logo",
    url: "",
  }
];

const Clients = () => {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    margin: 60,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
      <Slider {...settings}>
        {LogoSlider.map((val, i) => (
            <div className="item" key={i} >
              <div className="img-meta d-flex align-items-center justify-content-center" style={{"padding": "20"}}>
                <a href={val.url}>
                  <img src={`images/logo/${val.img}.png`} style={{paddingLeft: 30, paddingRight:30, maxWidth: 170}}/>
                </a>
              </div>
            </div>
        ))}
      </Slider>
  );
};

export default Clients;
