import React from "react";
import useDocumentTitle from "../../../../components/useDocumentTitle";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";
import ScrollspyNav from "react-scrollspy-nav";

const TermsConditions = () => {
    useDocumentTitle(
        "Zizy - Terms & Conditions"
    );
    return (
        <div className="doc-container main-page-wrapper">
            <Header/>
            {/* End Header */}

            {/* =====================================================
				Terms and Condition
			===================================================== */}

            <div className="terms_and_policy">
                <div className="container">
                    {/*<ScrollspyNav*/}
                    {/*    scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6"]}*/}
                    {/*    activeNavClass="active"*/}
                    {/*    offset={170}*/}
                    {/*    scrollDuration="300"*/}
                    {/*>*/}
                    <div className="row align-items-start">


                        <div className="col-lg-8">
                            {/* Tab panes */}
                            <div className="tab-content ">
                                <ul id="opt1">
                                    <h2 className="font-gilroy-bold"> Terms & Conditions</h2>
                                    <div className="update-date">LAST UPDATED: 01 September 2022</div>
                                    <h3>GENERAL TERMS AND CONDITIONS FOR ZIZY DIGITAL TECHNOLOGIES, SRL.
                                        ("ZIZY")</h3>
                                    <h3>1. SCOPE OF APPLICATION</h3>
                                    <p>
                                        The following General Terms and Conditions (the “Agreement”) apply to all
                                        contracts and
                                        services provided by ZIZY DIGITAL TECHNOLOGIES, SRL. (“ZIZY”) to any of its
                                        customers
                                        (“Customer”) with regard to the use of ZIZY’s services (the “Services”). These
                                        General Terms
                                        Conditions are applicable to and shall be accepted by the Customer prior to the
                                        commencement of the Services provision. This Agreement constitutes a material
                                        part of each
                                        contract unless expressly agreed otherwise in writing.
                                    </p>
                                    <p>
                                        Any deviating terms and conditions of the Customer as well as any deviations
                                        and/or
                                        amendments to this Agreement shall only become part of this Agreement if they
                                        have been
                                        expressly acknowledged by ZIZY in writing. This Agreement also applies
                                        exclusively if Adjust
                                        has not explicitly objected to any contrary terms and conditions. This Agreement
                                        applies to
                                        any future business transaction between ZIZY and the Customer, even without
                                        express
                                        reference thereto.
                                    </p>
                                    <p>
                                        The sole use of ZIZY’s Services implies the automatic, full and unreserved
                                        acceptance by the
                                        Customer of the provisions established in this Agreement in the version
                                        published on ZIZY's
                                        website at each time.
                                    </p>

                                    <p>Both, ZIZY and the Customer is a “Party”, and together the “Parties”, to this
                                        Agreement.</p>

                                    <h3>2. OFFER AND CONTRACT CONCLUSION</h3>
                                    <p>The subject matter of this Agreement are the Services as offered in the service
                                        description
                                        according to the Purchase Order. To ensure the proper functioning of the
                                        Services, ZIZY
                                        reserves the right to make technical changes and improvements to the Services
                                        within a
                                        reasonable scope. This Agreement between ZIZY and the Customer is executed upon
                                        signature of an individual order (“Purchase Order”). The signature of the
                                        Purchase Orders
                                        means the acceptance of this Agreement.</p>

                                    <p>The Customer must send the signed Purchase Order to ZIZY by mail or by email
                                        (“Acceptance”). In addition to any provisions contained in the Purchase Order,
                                        this
                                        Agreement shall apply and in case of a conflict between the Purchase Order and
                                        this
                                        Agreement the terms of the Order Form shall prevail.</p>


                                    <h3>3. CONTENT OF ZIZY’S SERVICES</h3>
                                    <p>ZIZY will perform the Services in accordance with the descriptions,
                                        specifications, fees and
                                        delivery schedule set forth in a Purchase Order or otherwise agreed between the
                                        Parties. ZIZY will promptly notify the Customer of any delay or anticipated
                                        delay in the
                                        performance of the Services, the reasons for the delay and the actions being
                                        taken by ZIZY
                                        to overcome or mitigate the delay.</p>
                                    <p>The use of the Deliverables by the Customer shall be limited to descriptive
                                        purposes only
                                        and under no circumstances may the Deliverables be used for other purposes
                                        (“Agreed
                                        Purposes”). Any use of the Deliverables other than as described herein as well
                                        as any
                                        subsequent use or modification of the Deliverables shall be the sole and
                                        exclusive
                                        responsibility of the Customer.</p>
                                    <p>The Customer shall not (a) modify, translate, reverse engineer, decompile,
                                        disassemble or
                                        otherwise create derivative works from ZIZY’s Property, of the Services or
                                        otherwise attempt
                                        to discover its underlying code, structure, implementation of algorithms or (b)
                                        transfer, lend,
                                        rent, lease, distribute the software, the Services or Deliverables provided by
                                        ZIZY, or use
                                        them for providing services to a third party, or grant any rights to a third
                                        party in any form,
                                        without ZIZY’s express prior written authorization and unless all respective
                                        Fees have been
                                        paid.</p>


                                    <h3>4. WARRANTIES</h3>
                                    <p>ZIZY represents and warrants that it holds all rights, authorizations, licenses
                                        and permits
                                        necessary to provide the Services. It also warrants that it will provide the
                                        Services with the
                                        diligence of a respectable businessman.</p>
                                    <p>The Services: (a) shall be of high quality; (b) shall be performed in a timely,
                                        workmanlike
                                        manner and with professional diligence and skill; and (c) will not contain any
                                        viruses or other
                                        malicious code. If ZIZY is in breach of any of the foregoing warranties, ZIZY
                                        will promptly
                                        re-perform the Services at no charge to the Customer.</p>
                                    <p>The Deliverables will be merchantable and fit for the purpose of this Agreement.
                                        The
                                        Deliverables will: (a) materially conform to the descriptions and specifications
                                        set forth in the
                                        applicable Purchase Order and (b) the media (if any) containing the Deliverables
                                        will be free
                                        from physical defects. If ZIZY is in breach of any of the foregoing warranties,
                                        ZIZY will
                                        replace and redeliver the Deliverables on an expedited basis at no charge to the
                                        Customer.
                                        The Deliverables will not contain any viruses or other malicious code.</p>
                                    <p>The Customer represents and warrants that it holds all rights, authorizations,
                                        licenses,
                                        trademarks, permits and consents necessary for the conduct of its business and
                                        for the
                                        marketing of its products and services. The performance of its activity will not
                                        harm ZIZY or
                                        ZIZY's image and, in particular, it may not introduce any type of virus or
                                        malware on the
                                        website or in ZIZY’s system.</p>
                                    <p>The Customer warrants that all data relevant for the conclusion and execution of
                                        this
                                        Agreement provided is complete and correct. The Customer is obliged to promptly
                                        inform
                                        ZIZY about any changes to this data and/or to update altered data.</p>
                                    <p>If the Customer has chosen a package with an unlimited number of attributions, it
                                        shall be
                                        prohibited from adding any of its subsidiaries, affiliates, group companies,
                                        other acquired
                                        companies, etc. (together referred to as 'Affiliates') to its account.</p>


                                    <h3>5. LIABILITY</h3>
                                    <p>Nothing in this Agreement shall be deemed to limit or exclude either party’s
                                        liability: (a) in
                                        respect of death or personal injury resulting from its negligence; (b) in
                                        respect of fraud or
                                        fraudulent misrepresentation; or (c) otherwise to the extent that such
                                        limitation or exclusion is
                                        not permitted by law.</p>
                                    <p>ZIZY shall be responsible that the Services correspond to their intended use.
                                        ZIZY does not
                                        assume any liability for any damages resulting from a usage other than the
                                        intended use.
                                        The same applies to any damages resulting from a usage that is not in accordance
                                        with
                                        ZIZY’s instructions and recommendations or any other unauthorized usage.</p>
                                    <p>In no event shall either Party be liable to the other Party for any special,
                                        indirect, or
                                        consequential loss or damage of any nature arising out of or related to this
                                        Agreement, even
                                        if such party has been advised of the possibility of such damages. The foregoing
                                        shall apply
                                        regardless of the negligence or other fault of either party and regardless of
                                        whether such
                                        liability arises in contract, tort, strict liability or any other theory of
                                        liability.</p>
                                    <p>ZIZY’s liability in respect of any Purchase Order in any year shall not exceed
                                        the amount paid
                                        by the Customer under that Purchase Order in the past two (2) months.</p>

                                    <h3>6. USE OF TRADEMARKS</h3>
                                    <p>Both Parties authorize the other Party to use, during the term of the provision
                                        of the Services the Intellectual Property Rights over its trademarks and
                                        commercial signs and in its products and services to the extent necessary or
                                        related to the provision of the Services (including to display the other Party
                                        trademarks on their respective websites) under the instructions that, where
                                        appropriate, one Party may give to the other Party (hereinafter, the “License”).
                                        The License is granted by the Parties on a non-exclusive, irrevocable, free,
                                        universal and sub-licensable basis to the Parties’ group companies.</p>

                                    <h3>7. DATA PROTECTION</h3>
                                    <p>The Services provided under this Agreement do not entail the processing of
                                        personal Data.</p>

                                    <h3>8. CONFIDENTIALITY</h3>
                                    <p>During the provision of the Service, both Parties may exchange information of a
                                        secret or
                                        confidential nature. In this regard, "Confidential Information" shall be
                                        understood to be all
                                        documentation and information, regardless of its form, that either Party
                                        provides to the other
                                        Party, including the groups’ companies information of each Party. Confidential
                                        Information
                                        shall include, but not be limited to: commercial, economic, strategic, know-how,
                                        marketing,
                                        trade secrets, studies, reports or information of a confidential nature of third
                                        parties that a
                                        Party provides, whether or not identified as confidential, or that is obtained
                                        by the other Party,
                                        before or after the acceptance of this Agreement. The obligation of
                                        confidentiality also
                                        applies to all employees, consultants, contractors or any third parties
                                        (hereinafter "Related
                                        Persons") acting on behalf of or in representation of either Party who have
                                        access to the
                                        Confidential Information. The Parties shall be liable for the breaches of the
                                        Related Persons
                                        in relation to the Confidential Information.</p>
                                    <p>ZIZY and the Customer shall use the Confidential Information received with the
                                        sole objective
                                        and purpose of providing and receiving the Services, which is the object of this
                                        Agreement, to
                                        which the provisions herein shall apply insofar as they are not modified by any
                                        other
                                        subsequent agreement.</p>
                                    <p>At any time, at the request of one of the Parties, the other Party shall
                                        immediately return or
                                        destroy all Confidential Information and copies thereof.</p>
                                    <p>The Confidential Information received may not be reproduced or disclosed in any
                                        form
                                        whatsoever, except for internal use when this is necessary for the fulfillment
                                        of the purpose
                                        described above, and provided that adequate measures are taken to ensure strict
                                        compliance with the duties of confidentiality by the Parties and the Related
                                        Persons. The
                                        Parties undertake, regarding the other Party’s Confidential Information to use,
                                        at least, the
                                        same level of diligence as they would use regarding their own Confidential
                                        Information, and
                                        in no event less than reasonable care.</p>
                                    <p>When either ZIZY or the Customer are legally required to deliver or disclose
                                        Confidential
                                        Information when required by law, a court of competent jurisdiction or any
                                        governmental or
                                        regulatory authority, prior written notice shall be given to affected Party,
                                        with the greatest
                                        possible urgency and with a copy of the documents and information relevant to
                                        such legal
                                        action, whenever legally possible.</p>
                                    <p>The duty of confidentiality set out in this clause shall remain in effect even
                                        after the
                                        termination of this Agreement for a period of five (5) years after
                                        termination.</p>

                                    <h3>9. INTELLECTUAL PROPERTY RIGHTS</h3>
                                    <p>Both Parties recognize the ownership of the other Party’s intellectual and
                                        industrial property rights, including but not limited to trademark rights,
                                        patents, trade secrets or image rights owned at the time of the Identification
                                        or at the time of starting the provision of the Service or arising as a
                                        consequence of the performance of their obligations, on the logos, ideas,
                                        software, platform, design, models and any other creation, sign or element
                                        protected by similar rights (hereinafter, the “Intellectual Property Rights”).
                                        The Customer declares that it knows and accepts that ZIZY’s Software and any
                                        other software, hardware, all Intellectual Property Rights over its website and
                                        its brand and all documents, work product and other materials that are delivered
                                        to the Customer hereunder or prepared by or on behalf of ZIZY in the course of
                                        performing the Services, including any items identified as such in a Purchase
                                        Order (together “Deliverables”), has been created by ZIZY (“ZIZY’s Property”).
                                        ZIZY is the sole producer of the data and information, and it therefore
                                        undertakes full responsibility for the method of producing the data (such as
                                        algorithms used to clean the names, scripts used for logos and other data
                                        manipulation methods). ZIZY will retain all intellectual, industrial or any
                                        other property rights over it and the Customer may not make any further
                                        modification, copy, alteration, reproduction, adaptation, or translation of
                                        it.</p>
                                    <p>Consequently, any use by the Customer of ZIZY’s Property made without ZIZY’s
                                        authorization is strictly prohibited. This includes their exploitation,
                                        reproduction, dissemination, transformation, distribution, transmission by any
                                        means, subsequent publication, exhibition, public communication or total or
                                        partial representation, which, if these do occur, will constitute infringements
                                        of ZIZY’s intellectual or industrial property rights, sanctioned by current
                                        legislation.</p>
                                    <p>Upon execution of this Agreement, ZIZY grants the Customer the non-exclusive,
                                        non-transferable and non-sublicensable right to use the Merchant Data during the
                                        term of this Agreement, insofar as this is necessary to use the Services
                                        according to the respective Purchase Order, but nor for any other purpose, such
                                        as internal processes. The right of use shall expire once the Customer defaults
                                        with any payments due.</p>
                                    <p>For clarification purposes Merchant Data means clean names and brand logos of
                                        Merchants as provided by ZIZY to the Customer.</p>
                                    <p>In the event of a contract termination, all ZIZY’s Property obtained for
                                        delivering the Services such as data collected and provided, shall be destroyed
                                        as well as any use and provide deletion logs as evidence no later than the last
                                        working day of the Agreement. The Customer shall provide documentation
                                        evidencing that all ZIZY’s Property has been deleted. Until this documentation
                                        is received, the Services will be deemed to continue to be provided and
                                        therefore Fees will continue to accrue.</p>
                                    <p>Within the Term and the provisions of this Agreement, the Customer shall be
                                        entitled to use any Merchant Data provided by Company to the Customer
                                        unrestricted only for the Agreed Purpose and for only as necessary to use the
                                        Services. Any other use of ZIZY’s Property and Merchant Data would constitute a
                                        breach of this agreement as well as a material breach of the intellectual
                                        property obligations agreed between ZIZY and the Customer.”</p>

                                    <h3>10. PRICE AND TERMS OF PAYMENT</h3>
                                    <p>The fees for the Services are set out in the applicable Purchase Order
                                        (“Fee”).</p>
                                    <p>ZIZY will issue an invoice within the first ten (10) days of each month to the
                                        Customer, which
                                        shall include the details of the monthly accrued Fee. Invoices will be sent to
                                        the Customer via
                                        email, unless expressly agreed otherwise. The Customer shall pay the invoice by
                                        bank
                                        transfer within fifteen (15) days of its electronic remittance.</p>
                                    <p>ZIZY shall have no obligation to provide the Services to the Customer if the
                                        Customer has
                                        not paid the Fee or if it does not comply with the terms of payment within 30
                                        calendar days of
                                        ZIZY notifying the Customer of the non-compliance.</p>

                                    <h3>11. TERM</h3>
                                    <p>The term of this Agreement shall be determined in each signed Purchase Order
                                        (“Term”).
                                        Notwithstanding the above, each Party has the right to terminate a Term by
                                        giving notice at
                                        least ninety (90) days prior to the end of each Term. The termination must be
                                        made in writing
                                        and be submitted via mail or email.</p>
                                    <p>The right to immediate termination for cause shall remain unaffected. In
                                        particular,
                                        ZIZY has
                                        the right to immediately terminate this Agreement:
                                        <ul>
                                            <li>If the Customer is in default of payment and does not settle the
                                                outstanding payment
                                                upon receipt of a warning letter with a deadline for payment and
                                                expiration
                                                of that
                                                deadline to no avail;
                                            </li>
                                            <li>If the Customer is insolvent, subject to insolvency proceedings,
                                                insolvency
                                                proceedings have been commenced or the commencement of insolvency
                                                proceedings is dismissed due to lack of assets;
                                            </li>
                                            <li>If the Customer violates the provisions of this Agreement and fails to
                                                remedy this
                                                violation upon receipt of a written request with an adequate deadline.
                                                No
                                                such
                                                request is necessary if it has no prospect of success or if the
                                                violation is
                                                so serious
                                                that ZIZY cannot be reasonably expected to adhere to this Agreement. A
                                                violation is
                                                also deemed serious if the Customer has received notices of warnings
                                                several
                                                times
                                                because of similar violations.
                                            </li>
                                        </ul></p>
                                    <p>Upon termination of this Agreement, the Customer is obliged to delete all Data
                                        and all
                                        Deliverables delivered by ZIZY during the provision of the Services.</p>
                                    <p>Any modification introduced to these General Terms and Conditions will be
                                        notified to the
                                        Customer by ZIZY prior to its application. The modifications shall be deemed
                                        accepted if the
                                        Customer does not inform ZIZY of its non-conformity. If the Customer rejects the
                                        changes,
                                        ZIZY has the right to terminate this Agreement.</p>
                                    <p>Following any termination or expiration of this Agreement or of any Purchase
                                        Order, (a) ZIZY
                                        will not be obligated to continue performing any such terminated Services, (b)
                                        the Customer
                                        will pay ZIZY all the accrued and due Fees earned prior to termination and (c)
                                        each Party will
                                        return any Confidential Information or property of the other party within ten
                                        (10) days from the
                                        date of such termination.</p>


                                    <h3>12. TECHNICAL SUPPORT</h3>
                                    <p>ZIZY undertakes to provide the Customer with the appropriate technical support to
                                        resolve
                                        incidents regarding the Services, at no cost to the Customer. ZIZY will provide
                                        the personnel
                                        and material resources at its disposal to resolve incidents related to ZIZY’s
                                        Service to the
                                        Customer as soon as possible.</p>
                                    <p>All incidents must be reported by email as soon as possible to the e-mail address
                                        office@zizy.io.The Customer undertakes to provide the necessary data, whenever
                                        available,
                                        required to be attended by the technical support service.</p>
                                    <p>Any complaint must be made in writing within thirty (30) calendar days of the
                                        provision of the
                                        Services that are the subject of the complaint. ZIZY's liability shall in all
                                        cases be limited to
                                        correcting or re-performing the Services that have been provided in breach, this
                                        being the
                                        only right that may be claimed by the Customer.</p>


                                    <h3>13. ASSIGNMENT</h3>
                                    <p>Neither Party may assign its rights or obligations arising from this Agreement
                                        without the
                                        prior written consent of the other Party, except in the case of an assignment in
                                        favor of any
                                        group company, provided that: (i) the assignee undertakes in writing to be bound
                                        by the
                                        terms of this Agreement and (ii) the assignor informs the other Party of this
                                        assignment.</p>

                                    <h3>14. MISCELLANEOUS</h3>
                                    <p>In the event that any of the clauses included in this Agreement should be
                                        declared totally or
                                        partially null and void or ineffective, such nullity shall only affect said
                                        clause or the part
                                        thereof that is null and void or ineffective, and the rest of the clauses shall
                                        remain in force in
                                        all other respects.</p>
                                    <p>Any deviating terms and conditions of the Customer as well as any deviations
                                        and/or
                                        amendments to this Agreement shall only become part of this Agreement if they
                                        have been
                                        agreed by the Parties in writing, through a Purchase Order.</p>

                                    <h3>15. APPLICABLE LAW AND JURISDICTION</h3>
                                    <p>The relationship between the Parties shall be governed by Romanian law. For any
                                        controversy directly or indirectly related to the Agreement or the Purchase
                                        Order, the Parties
                                        submit to the Courts and Tribunals of Romania.</p>

                                    All rights reserved. October 2022. ZIZY DIGITAL TECHNOLOGIES, SRL.


                                </ul>
                            </div>

                        </div>


                        {/*  /.tab-content */}
                    </div>
                    {/*</ScrollspyNav>*/}
                </div>
            </div>
        </div>
    );
};


export default TermsConditions;
