import React from "react";
import useDocumentTitle from "../../components/useDocumentTitle";
import HeaderLanding from "../../components/header/landing/HeaderLanding";
import HeroBannerFive from "../../components/hero-banner/HeroBannerFive";

import FancyFeatureEleven from "../../components/features/FancyFeatureEleven";
import Pricing from "../../components/pricing/pricing-four/Pricing";
import FooterFive from "../../components/footer/FooterFive";
import MainPageCode from "../inner-pages/docs/MainPageCode";
import {Link} from "react-router-dom";
import * as ReactGA from "react-ga";
import Clients from "../../components/brand/Clients";
import SupportedNetworks from "../../components/brand/SupportedNetworks";

ReactGA.initialize('UA-155449904-1');
ReactGA.pageview('/');

const ProductLanding = () => {
  useDocumentTitle("Zizy - Enhancing Transactions Data");

  return (
    <div className="main-page-wrapper font-gordita" style={{}}>
      <HeaderLanding />
      {/* End HeaderLanding */}

      <HeroBannerFive/>


      <div className="fancy-feature-eleven pt-130 md-pt-80" id="products">
        <div className="inner-container">
          <FancyFeatureEleven />
        </div>
        {/* /.inner-container */}
        <img
          src="images/shape/139.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/140.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/* /.fancy-feature-eleven */}

      <div className="pricing-section-four pt-130 md-pt-100" id="apidocs">
        <div className="container">
          <div className="row">
            <div className="col-xl-10  m-auto">
              <div className="title-style-six text-center">
                <h2>
                  Super easy-to-use API
                </h2>
              </div>
              {/* /.title-style-six */}
            </div>
          </div>
        </div>
        {/* End .container */}

      <MainPageCode/>
      </div>


      {/*  =====================================================
				Pricing Section Four
			===================================================== */}
      <div className="pricing-section-four  md-pt-100" id="pricing">
        <div className="container">
          <div className="row">
            <div className="col-xl-10  m-auto">
              <div className="title-style-six text-center">
                <h2>
                  5-people startup or already a unicorn? We’ve got you <span>covered.</span>
                </h2>
              </div>
              {/* /.title-style-six */}
            </div>
          </div>
        </div>
        {/* End .container */}

        <Pricing />
      </div>
        {/*/.pricing-section-four*/}

      <div className="partner-slider-two mt-110 md-mt-80">
        <div className="container">
          <p className="text-center">
            Supported networks
          </p>
          <div className="partnerSliderTwo">
            <SupportedNetworks />
          </div>
          {/* End .partner slider two */}
        </div>
      </div>


      {/*  =====================================================
				Fancy Short Banner Six
			===================================================== */}
      <div className="fancy-short-banner-six mt-150 md-mt-80">
        <img
          src="images/shape/143.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <div className="container">
          <div className="row">
            <div
              className="col-xl-9 col-lg-11 m-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="title-style-six text-center">
                <h2>
                  love our <span>products</span>? <br />
                  Let's have a chat.
                </h2>
              </div>
              {/* /.title-style-six */}
            </div>
          </div>
          <p data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
            You can try it risk free -  No fees. No strings attached.
          </p>
          <a
            href="mailto:office@zizy.io"
            className="theme-btn-seven"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="150"
          >
            Contact us now
          </a>
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-six */}

      {/* =====================================================
				Footer Style Five
			===================================================== */}
      <footer className="theme-footer-five mt-130 md-mt-100">
        <div className="inner-container">
          <div className="container">
            <FooterFive />
          </div>
        </div>
        {/* /.inner-container */}

        <div className="d-md-flex align-items-center justify-content-between" style={{"margin-left": "10%", "margin-right": "10%"}}>

        <ul className="order-md-last">
          <li>
            <Link to="/terms-conditions">Terms & Conditions</Link>
          </li>
        </ul>

        <p className="copyright">
          {" "}
          Copyright @{new Date().getFullYear()}{" "}
          <a
            href="https://zizy.io"
            target="_blank"
            rel="noreferrer"
          >
            Zizy
          </a>{" "}
          - Enhancing transactions data.
        </p>

        </div>


      </footer>
      {/*  /.theme-footer-five */}
    </div>
  );
};

export default ProductLanding;
